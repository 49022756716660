:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;

  color-scheme: light dark;
  color: var(--black);
  background-color: var(--tan);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --white: #EDEDE9;
  --dark-tan: #D6CCC2;
  --tan: #F5EBE0;
  --earth: #E3D5CA;
  --dark-earth: #D5BDAF;
  --black: #212529;
}
