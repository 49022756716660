#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

html,
body {
  overflow-x: hidden;
}

/* ----------------------------NAVBAR---------------------------- */
.social {
  color: var(--black);
}
#navbar {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 5px solid var(--earth);

}
.nav-logo {
  max-width: 300px;
}
.nav-links {
  display: flex;
  gap: 25px;
  align-items: center;

}

.nav-links a,
.nav-logo a {
  text-decoration: none;
  color: var(--black);
}

.nav-booking {
  padding: 5px 10px;
  background-color: transparent;
  color: var(--black);
}

.nav-links a:hover,
.nav-booking:hover,
.nav-logo:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.nav-links a:hover {
  border-bottom: 2px solid var(--earth);
}
.title,
.subtitle {
  font-size: 20px;
}

/* ----------------------------HOME---------------------------- */

#home,
#home-reviews,
#home-images,
#homebook,
.home-intro-container,
#home-hours,
#home-footer,
.home-footer-payments,
.home-services-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.home-intro-container {
  margin: auto;
  padding-top: 5px;
  padding-bottom: 25px;
}

.home-intro {
  margin: auto;
  border: 5px solid var(--earth);
  box-shadow: 5px 5px var(--dark-earth);
}

.home-name {
  font-family: 'Libre Baskerville', serif;

  font-size: 25px;
}

.home-service {
  font-size: 30px;
}

.home-services-container {
  font-size: 40px;
  border-bottom: 5px solid var(--earth);
}

.home-name {
  font-weight: 500;
}

.home-images-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-images-container img {
  border: 2px solid var(--black);
  width: 100%;
}

#homebook,
#home-hours {
  border-top: 5px solid var(--dark-earth);
  border-bottom: 5px solid var(--dark-earth);
}

#home-images {
  border-top: 5px solid var(--dark-earth);
}

.homebook-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: auto;
}

.homebook-button {
  padding: 25px 50px;
  font-size: 25px;
  background-color: transparent;
  color: var(--black);
}

.homebook-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.home-reviews-container,
.day-hours-container {
  display: flex;
  justify-content: space-around;
  font-size: 25px;
}

.home-reviews-item-container {
  max-width: 50%;
}

.review-name {
  font-size: 20px;
}

.home-reviews-title,
.home-images-title,
.home-hours-title,
.footer-accepted-payments-title,
.footer-name {
  font-size: 40px;
  padding-bottom: 25px;
  text-decoration: underline;
}

.day-hours-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.home-footer-container {
  font-size: 25px;
}

.home-footer-intro-container {
  border-bottom: 5px solid var(--dark-earth);
  padding-bottom: 50px;
}

#home {
  padding-top: 10px;
}
.footer-phone {
  color: var(--black);
}
.meraki-logo {
  height: 100px;
  padding-top: 10px;
}
#home,
.home-footer-payments {
  padding-bottom: 0px;
}



/* -------------------------------SERVICES------------------------------- */


#services {
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.services-title {
  font-size: 45px;
}

.services-subtitle {
  font-size: 30px;
}
.services-disclaimer {
  max-width: 50%;
  padding-top: 5px;
  font-size: 15px;
  padding-bottom: 25px;
  margin: auto;
}

.services-container-upper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 25px;
  border-top: 5px solid var(--earth);

  gap: 200px;
}
.deep-center {
  text-align: start;
}

.services-item-name {
  font-size: 40px;
}

.services-item {
  max-width: 300px
}

.services-item-prices-container {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.services-container-under {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 5px solid var(--earth);
  gap: 25px;
}

/* ----------------------ABOUT ME---------------------- */

#about {
  padding-top: 25px;
}

.about-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 25px;
  gap: 15px;
}

.about-portrait {
  max-width: 100%
}

.about-portrait img {
  max-width: 100%;
}

.about-description {
  max-width: 50%;
  font-size: 20px;
}

.description-name {
  font-size: 30px;
  padding-bottom: 10px;
}

.about-title {
  font-size: 40px;
  text-decoration: underline;
  padding-bottom: 25px;
  border-bottom: 5px solid var(--earth);
}



/* ----------------------CONTACT---------------------- */
.contact-title {
  padding-top: 25px;
  font-size: 40px;
  text-decoration: underline;
  border-bottom: 5px solid var(--earth);
  padding-bottom: 25px;
}

.contact-container {
  display: flex;
  justify-content: space-around;
  padding-top: 25px;

}

.facebook svg,
.instagram svg,
.tiktok svg {
  height: 150px;
}

.email button {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 25px 50px;
  font-size: 25px;
  background-color: transparent;
  color: var(--black);
}

.facebook:hover,
.instagram:hover,
.tiktok:hover,
.email button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

/* ----------------------REVIEWS---------------------- */

#reviews {
  padding-top: 25px;
}

.reviews-title {
  font-size: 40px;
  text-decoration: underline;
  padding-bottom: 25px;
  border-bottom: 5px solid var(--earth);

}

.reviews-container {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.reviews-item-container {
  /* border: 5px solid var(--earth); */
  max-width: 50%;
  font-size: 25px;
  /* box-shadow: 3px 3px var(--dark-earth); */
  padding: 5px;
}

/* ----------------------GALLERY---------------------- */

#gallery {
  padding-top: 25px;
}

.gallery-title {
  font-size: 40px;
  text-decoration: underline;
  padding-bottom: 25px;
  border-bottom: 5px solid var(--earth);
}
.gallery-container {
  padding-top: 25px;
}



/* ----------------------POLICY---------------------- */

#policy {
  padding-top: 25px;
  font-size: 20px;
}

.policy-title {
  font-size: 30px;
  padding-bottom: 25px;
  border-bottom: 5px solid var(--earth);

}

.policy-description {
  padding-top: 25px;
  max-width: 75%;
  margin: auto;
}

.payment-container {
  padding-top: 100px;
  font-weight: 300;
  max-width: 75%;
  margin: auto;
}

.policy-disclaimer {
  font-style: italic;
  max-width: 75%;
  margin: auto;
  padding-top: 50px;
}

/* ----------------------MOBILE---------------------- */

@media screen and (max-width: 650px) {
  html {
    overflow-x: hidden;
  }

  .services-container-upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    gap: 10px;
  }

  .contact-container {
    flex-direction: column;
    gap: 15px;
  }

  .about-container {
    display: flex;
    flex-direction: column;
  }

  .about-description {
    max-width: 100%;
  }

  .gallery-container {
    display: flex;
    gap: 10px;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .image-container {
    object-fit: cover;
  }

  .image-container img {
    max-height: none;
    margin-top: 0;
    object-fit: cover;
    height: 125px;
    width: 125px;
  }
  .reviews-item-container {
    max-width: none;
  }
}

@media screen and (max-width: 900px) {
  .menu {
    visibility: visible;
    position: absolute;
    right: 50px;
    top: 95px;
  }

  .nav-links {
    visibility: hidden;
  }

  .nav-links-responsive {
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 2;
    background-color: var(--tan);
    margin: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 170px;
    padding-bottom: 20px;
    transition: 1s ease;
    align-items: center;
  }

  .nav-links-responsive a {
    text-decoration: none;
    color: var(--black);
    font-size: 24px;
    border-bottom: 2px solid var(--dark-earth);
  }

  .nav-links-responsive button {
    font-size: 24px;
    padding: 7px 80px;
  }

  .hours-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .home-reviews-item-container {
    max-width: none;
  }
  .home-reviews-container {
    flex-direction: column;
    align-items: center;
    gap: 5px
  }
  .home-reviews-container,
  .day-hours-container {
    font-size: 20px;
  }
}
@media screen and (max-width: 350px) {
  #navbar {
    display: flex;
    flex-direction: column;
  }
  .menu {
    position: unset;
  }
  .nav-links-responsive {
    top: 200px;
  }
  html {
    word-wrap: break-word;
  }
}
@media screen and (min-width: 900px) {
  .menu {
    visibility: hidden;
  }

}

@media screen and (min-width: 675px) {
  .gallery-container {
    display: flex;
    gap: 50px;
    max-width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }

  .image-container {
    max-width: 600px;
  }

  .image-container img {
    max-height: 300px;
    margin-top: 8px;
    border: 2px solid var(--dark-earth);
  }


}